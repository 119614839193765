import _export from "../internals/export";
import _arrayIncludes from "../internals/array-includes";
import _fails from "../internals/fails";
import _addToUnscopables from "../internals/add-to-unscopables";
var $ = _export;
var $includes = _arrayIncludes.includes;
var fails = _fails;
var addToUnscopables = _addToUnscopables;

// FF99+ bug
var BROKEN_ON_SPARSE = fails(function () {
  // eslint-disable-next-line es/no-array-prototype-includes -- detection
  return !Array(1).includes();
});

// `Array.prototype.includes` method
// https://tc39.es/ecma262/#sec-array.prototype.includes
$({
  target: "Array",
  proto: true,
  forced: BROKEN_ON_SPARSE
}, {
  includes: function includes(el /* , fromIndex = 0 */) {
    return $includes(this, el, arguments.length > 1 ? arguments[1] : undefined);
  }
});

// https://tc39.es/ecma262/#sec-array.prototype-@@unscopables
addToUnscopables("includes");
export default {};